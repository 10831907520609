<template>
  <section class="conAllSettings">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="headerSettingPage headerOfPage">Time Mangement</div>
        </v-col>
      </v-row>
    </v-container>
    <section class="contentSettingPage mt-3">
      <v-container fluid>
        <v-row>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'TMsignin' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon>mdi-login</v-icon>
                    <div class="conTxt">Sign In</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'adminOverview' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon>mdi-home</v-icon>
                    <div class="conTxt">Overview</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'categories' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon>mdi-application-edit</v-icon>
                    <div class="conTxt">Projects Categories</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'attendancepages' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center">mdi-pencil</v-icon>
                    <div class="conTxt">Attendance</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'exportFiles' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon>mdi-calendar-export</v-icon>
                    <div class="conTxt">Exports</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'Reports' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-file-chart-outline</v-icon
                    >
                    <div class="conTxt">Reports</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'addTasks' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center">mdi-note-plus</v-icon>
                    <div class="conTxt">TASKS</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'addTags' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center">mdi-note-plus</v-icon>
                    <div class="conTxt">TAGS</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'working' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-semantic-web</v-icon
                    >

                    <div class="conTxt">working</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'timesheets' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-note-multiple-outline</v-icon
                    >
                    <div class="conTxt">Time Sheets</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'taskstatus' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-note-multiple-outline</v-icon
                    >
                    <div class="conTxt">Task Status</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style lang="scss" scoped>
@import "./_adminpages.scss";
</style>
